import {Axios} from "@/utils/axios";
import {Accomodation} from "@/models/accomodation";
import {Room} from "@/models/room";


export class AccomodationServiceClass {

	public url: string = "https://booking-api.webcode.be/api/public"

	async single(id: number) {
		try {
			let response = await Axios.get(this.url + "/accomodation/" + id);
			if (response.status == 200) {
				return new Accomodation(response.data.data);
			}
		} catch (e) {

		}
	}

	async rooms(id: number) {
		try {
			let response = await Axios.get(this.url + "/rooms/" + id);
			if (response.status == 200) {
				return response.data.data.map((r:Room) => { return new Room(r) });
			}
		} catch (e) {

		}
	}


}

export const AccomodationService = new AccomodationServiceClass();