export class AdditionalGuest {
	FirstName: string = "";
	LastName: string = "";
	DateOfBirth: string = "";

	public constructor(data?: any) {
		if (!data) return;
		this.FirstName = data.FirstName;
		this.LastName = data.LastName;
		this.DateOfBirth = data.DateOfBirth;
	}
}