<template>
  <div v-if="loaded" id="app">
    <b-container>
      <b-row v-if="!booking.Payed">
        <b-col class="top" md="12" sm="12">
          <img class="logo" src="https://gtkeiheuvel.be/public/templates/frontend/default/assets/img/logo.jpg"/>
          <h1>{{ accomodation.Name }}</h1>
        </b-col>
        <b-alert v-if="!accomodation.ID" show variant="danger">Opgelet, geen accomodatie geselecteerd, ga naar <a
            href="https://gtkeiheuvel.be/accomodaties">ons accomodatie overzicht</a>.
        </b-alert>
        <b-col :md="8" sm="12">
          <div v-if="step==1 && accomodation.ID">
            <date-range :accomodation="accomodation" :occupation="occupation" :value="booking" @next="step=2" @date-change="changed"/>
          </div>
          <div v-if="step==2">
            <guest-list :value="booking" :accomodation="accomodation"  @next="step=3" @prev="step=1"/>
          </div>
          <div v-if="step==3">
            <room-select :accomodation="accomodation" :value="booking" @next="step=4" @prev="step=2"/>
          </div>
          <div v-if="step==4">
            <extra-options :accomodation="accomodation" :value="booking" @next="step=5" @prev="step=3"/>
          </div>
          <div v-if="step==5">
            <guest-details ref="guestDetails" :do-validation="doValidation" :value="booking" @next="step=6"
                           @prev="step=4"/>
          </div>
          <div v-if="step==6">
            <b>U wordt doorverwezen naar onze betaalpagina.</b>
          </div>
        </b-col>
        <b-col md="4" sm="12">
          <over-view :accomodation="accomodation" :step="step" :value="booking" @next="validate()" @prev="step=5"/>
        </b-col>
      </b-row>
      <b-row v-else>
        <payed-ok :value="booking"/>
        <b-col md="4" sm="12">
          <over-view :accomodation="accomodation" :step="step" :tiny="true" :value="booking" @next="validate()"
                     @prev="step=5"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import OverView from "@/components/OverView.vue"
import DateRange from "@/components/DateRange.vue"
import GuestList from "@/components/GuestList.vue"
import RoomSelect from "@/components/RoomSelect.vue"
import ExtraOptions from "@/components/ExtraOptions.vue"
import GuestDetails from "@/components/GuestDetails.vue"
import PayedOk from "@/components/PayedOk.vue"
import {Booking} from "@/models/booking";
import {AccomodationService} from "@/services/accomodation-service";
import {Accomodation} from "@/models/accomodation";
import {BookingService} from "@/services/booking-service";

export default {
  name: 'App',
  data() {
    return {
      booking: new Booking(),
      accomodation: new Accomodation(),
      step: 1,
      loaded: false,
      doValidation: false,
      formsCompleted: false,
      occupation: []
    }
  },
  methods: {
    validate() {
      this.$refs.guestDetails.validate();
    },
    async getOccupation(start,index) {
      let id = this.$route.query.accomodation;
      let d = new Date()
      let s = new Date(d.getFullYear(), (d.getMonth()), 1);

      if(start){
        if(index==1){
          d = new Date(start)
          let p = d.getMonth()-1;
          s = new Date(d.getFullYear(), (p == 0 ? 12 : p), 1);
        }else{
          d = new Date(start)
          s = new Date(d.getFullYear(), (d.getMonth()), 1);
        }
      }

      this.occupation = await BookingService.occupation(id, s)
    },
    changed(event, index) {
      this.getOccupation(event,index);
    },
  },
  async mounted() {
    let id = this.$route.query.accomodation;
    let t = this.$route.query.t;

    this.accomodation = await AccomodationService.single(id);
    this.booking.IdAccomodation = id;
    this.booking.MaxGuests = this.accomodation.MaxGuests
    this.booking.MaxChildren = this.accomodation.MaxChildren
    this.booking.Options = this.accomodation.Options
    if (t) {
      this.booking = await BookingService.recover(t);
    }
    //this.getOccupation();
    this.loaded = true;
  },
  components: {
    DateRange,
    GuestList,
    RoomSelect,
    ExtraOptions,
    GuestDetails,
    OverView,
    PayedOk
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
}
h1 { font-size: 14px !important; opacity: 0.7; }
.logo { max-width: 80px; }
.top { margin-bottom: 10px; }
</style>
