<template>
  <div class="mb-5">
    <b-row no-gutters>
      <b-col sm="12" >
        <h2>Selecteer het aantal personen</h2>
        <h3>Deze accomodatie is geschikt voor {{ parseInt(booking.MaxChildren + booking.MaxGuests) }} personen</h3>
      </b-col>
      <b-col sm="12" class="mt-2">
        <b-list-group>
          <b-list-group-item class="d-flex flex-column">
            <div class="d-flex flex-row align-items-center">Volwassenen (5 jaar en ouder)
            <plus-minus :max="(booking.MaxGuests-booking.Children)" :min="1" :style="{marginLeft:'auto'}"
                        :value="booking.Guests"
                        v-bind:val.sync="booking.Guests"/>
            </div>
            <div>
              <div class="text-info" v-if="accomodation.FreeGuestsIncluded>2">Tot {{ accomodation.FreeGuestsIncluded }} gasten inbegrepen zonder meerkost</div>
            </div>
          </b-list-group-item>
          <!--<b-list-group-item class="d-flex flex-row align-items-center">
            Kinderen (4-10 jaar)
            <plus-minus :max="(booking.MaxGuests-booking.Guests)" :min="0" :style="{marginLeft:'auto'}"
                        :value="booking.Children"
                        v-bind:val.sync="booking.Children"/>
          </b-list-group-item>-->
          <b-list-group-item class="d-flex flex-row align-items-center">
            Peuters (0-4 jaar)
            <plus-minus :max="10" :min="0" :style="{marginLeft:'auto'}"
                        :value="booking.Babies"
                        v-bind:val.sync="booking.Babies"/>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="mt-3 date-result">
      <b-col class="d-flex flex-row">
        <b-button @click="prev()">Vorige stap</b-button>
        <b-button :style="{marginLeft:'auto'}" @click="next()">Volgende stap</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {Booking} from "@/models/booking";
import PlusMinus from "@/components/PlusMinus.vue"
import {AdditionalGuest} from "@/models/additional-guest";
import {AdditionalChild} from "@/models/additional-child";
import {Accomodation} from "@/models/accomodation";

export default {
  data() {
    return {
      booking: this.value
    }
  },
  watch: {
    booking() {
      this.$emit("input", this.booking);
    }
  },
  props: {
    value: [Booking],
    accomodation: [Accomodation]
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    next() {
      this.$emit('next');
      let d1 = this.booking.Guests - this.booking.AdditionalGuests.length;
      if ((d1-1) < 0) {
        this.booking.AdditionalGuests.splice(d1-1);
      } else if (d1 > 0) {
        for (let i = 0; i < d1-1; i++) {
          this.booking.AdditionalGuests.push(new AdditionalGuest());
        }
      }
      d1 = this.booking.Children - this.booking.AdditionalChildren.length;
      if ((d1) < 0) {
        this.booking.AdditionalChildren.splice(d1);
      } else if (d1 > 0) {
        for (let i = 0; i < d1; i++) {
          this.booking.AdditionalChildren.push(new AdditionalGuest());
        }
      }
      d1 = this.booking.Babies - this.booking.AdditionalBabies.length;
      if ((d1) < 0) {
        this.booking.AdditionalBabies.splice(d1);
      } else if (d1 > 0) {
        for (let i = 0; i < d1; i++) {
          this.booking.AdditionalBabies.push(new AdditionalGuest());
        }
      }

    },
  },
  components: {
    PlusMinus
  }
}
</script>

<style scoped>
h3{ font-size: 15px; opacity: 0.7; }
</style>