import {MainGuest} from "./main-guest";
import {AdditionalGuest} from "./additional-guest";
import {AdditionalChild} from "./additional-child";
import {AccomodationOption} from "@/models/accomodation-option";

export class Booking {
	IdAccomodation: number = 0;
	IdRoom: number = 0;
	RoomName: string = "";
	Status: number = 1;
	Name: string = "";
	From: null | Date = null;
	Till: null | Date = null;
	Guests: number = 2;
	Children: number = 0;
	Babies: number = 0;
	Dogs: number = 0;
	MaxGuests: number = 6;
	MaxChildren: number = 6;
	MainGuest: MainGuest = new MainGuest();
	AdditionalGuests: AdditionalGuest[] = [];
	AdditionalChildren: AdditionalGuest[] = [];
	AdditionalBabies: AdditionalGuest[] = [];
	Options: AccomodationOption[] = [];
	DepositCost: number = 0;
	CancellationInsurance: boolean = false;
	CancellationInsuranceCost: number = 25;
	Payed: boolean = false;


	public constructor(data?: any) {
		if (!data) return;
		this.Status = data.Status;
		this.Name = data.Name || "";
		this.IdAccomodation = data.iDAccomodation || 0;
		this.IdRoom = data.idRoom || 0;
		this.RoomName = data.RoomName || "";
		this.From = new Date(data.From) || new Date();
		this.Till = new Date(data.Till) || new Date();
		this.MaxGuests = data.MaxGuests;
		this.MaxChildren = data.MaxChildren;
		this.MainGuest = new MainGuest(data.MainGuest);
		this.AdditionalGuests = data.AdditionalGuests.map((u:AdditionalGuest) => new AdditionalGuest(u))
		this.AdditionalChildren = data.AdditionalChildren.map((u:AdditionalGuest) => new AdditionalGuest(u))
		this.AdditionalBabies = data.AdditionalBabies.map((u:AdditionalGuest) => new AdditionalGuest(u))
		this.Options = data.Options.map((o:AccomodationOption)=> new AccomodationOption(o))
		this.DepositCost = data.DepositCost
		this.CancellationInsurance = data.CancellationInsurance
		this.Payed = data.Payed

	}

	totalDays(){

		if(!this.Till || !this.From)
			return 0;

		let t = this.Till.getTime() - this.From.getTime();
		return Math.ceil(t / (1000 * 3600 * 24));

	}


}