<template>
  <div class="mb-5">
    <b-row no-gutters>
      <b-col sm="12" >
        <h2>Wil jij nog extra's toevoegen aan jouw boeking?</h2>
      </b-col>
      <b-col lg="12" sm="12" class="mt-2">
        <div>
          <b-list-group>
            <b-list-group-item class="d-flex flex-row align-items-center">
              <div class="form-check form-switch form-select-lg">
                <input id="flexSwitchCheckDefault" v-model="booking.CancellationInsurance" class="form-check-input" role="switch" type="checkbox">
                <label class="form-check-label" for="flexSwitchCheckDefault">Annulatieverzekering</label>
              </div>
            </b-list-group-item>
            <b-list-group-item class="d-flex flex-column " :key="option.ID" v-for="(option,index) in accomodation.Options">
              <div class="d-flex flex-row align-items-center" v-if="option.Available>1">
              <plus-minus :value="value.Options[index].Qty"
                            :max-width="50" :disabled="true"
                            v-bind:val.sync="booking.Options[index].Qty"
                            :max="option.Available" :min="0" />
              {{ option.Name }} ( &euro; {{ option.Price }} {{ option.Multiply ? ' / nacht' : '' }} )
              </div>
              <div class="form-check form-switch form-select-lg" v-if="option.Available==1">
                <input :id="'option_'+option.ID" v-model="option.Qty" class="form-check-input" role="switch" type="checkbox">
                <label class="form-check-label" :for="'option_'+option.ID">{{ option.Name }} ( &euro; {{ option.Price }} {{ option.Multiply ? ' / nacht' : '' }} )</label>
              </div>
              <div v-if="option.Info" class="d-flex w-100 justify-content-between info">
                {{ option.Info }}
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
      <b-col sm="12" md="8" class="mt-2">

      </b-col>
    </b-row>
    <b-row class="mt-3 date-result" no-gutters>
      <b-col sm="12" class="d-flex">
        <b-button @click="prev()">Vorige stap</b-button>
        <div :style="{flexGrow:1}"/>
        <b-button @click="next()">Volgende stap</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>


import {Booking} from "@/models/booking";
import {Accomodation} from "@/models/accomodation";
import PlusMinus from "@/components/PlusMinus.vue"

export default {
  data() {
    return {
      checked: false,
      booking: this.value
    }
  },
  props: {
    value: [Booking],
    accomodation: [Accomodation]
  },
  watch: {
    booking() {
      this.$emit("input", this.booking);
    }
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    next() {
      this.$emit('next');
    }
  },
  components:{
    PlusMinus
  }
}
</script>

<style  scoped>
   .info{ display: flex; background: #dee7f8; padding: 4px 6px; border-radius: 2px; border:1px solid #93c0f8; color: #488fe7;}
</style>