<template>
  <div class="mb-5">
    <div>
      <b-row>
        <b-col>
          <h2>Selecteer een aankomst en vertrekdag</h2>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <date-range-picker
            ref="picker"
            v-model="dateRange"
            :autoApply="true"
            :date-format="dateFormat"
            :locale-data="{
        firstDay: 1, format: 'dd-mm-yyyy',
        daysOfWeek: ['Zo', 'Ma', 'Di', 'Woe', 'Do', 'Vr', 'Za'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
      }"
            :minDate="minDate"
            :opens="opens"
            :ranges="false"
            :showDropdowns="false"
            :showWeekNumbers="false"
            :singleDatePicker="false"
            :timePicker="false"
            @toggle="logEvent('event: open', $event)"
            @update="updateValues"
            @start-selection="startSelection($event)"
            @finish-selection="finishSelection($event)"
            @change-month="changed"
        >
        </date-range-picker>
      </b-col>
    </b-row>
    <b-row v-if="booking.Till && !showAlert" class="mt-3 date-result">
      <b-col>
        <p class="mb-1">Aankomstdatum: {{ niceDate(booking.From) }}</p>
        <p class="mt-0">Vertrekdatum: {{ niceDate(booking.Till) }}</p>
      </b-col>
      <b-col class="d-flex flex-column">
        <b-button @click="next()">Volgende stap</b-button>
      </b-col>
    </b-row>
    <b-modal id="bv-modal" v-model="showAlert" :header-bg-variant="'danger'" :header-text-variant="'light'">
      <template #modal-title>
        Opgelet
      </template>
      <div class="d-block">
        <div>Deze datum selectie is niet geldig</div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              class="float-right"
              size="sm"
              variant="danger"
              @click="showAlert=false"
          >
            Sluiten
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {Booking} from "@/models/booking";
import {Accomodation} from "@/models/accomodation";

export default {
  data() {
    return {
      showAlert: false,
      opens: "inline",
      selectionFinished: false,
      dateRange: {
        startDate: null,
        endDate: null
      },
      minDate: new Date(),
      booking: this.value,
      disabledDates: ["2022-02-15", "2022-02-16"]
    }
  },
  watch: {
    booking() {
      this.$emit("input", this.booking);
    }
  },
  computed: {
    occupationDays() {

      if(!this.occupation){
        return [];
      }

      let a = [];
      for (let i = 0; i < this.occupation.length; i++) {
        if(parseInt(this.occupation[i]['aant'])>=this.accomodation.Qty){
          a.push(this.occupation[i]['day'])
        }
      }

      return a;
    }
  },
  props: {
    value: [Booking],
    occupation: [],
    accomodation: [Accomodation]
  },
  methods: {
    logEvent(text, event) {

    },
    changed(event, index) {
      this.$emit("date-change", event, index);
    },
    updateValues(event) {

    },
    startSelection(date) {
      this.booking.From = date;
      this.booking.Till = null;
      this.selectionFinished = false;
    },
    finishSelection(date) {
      if (date.toString() == this.booking.From.toString()) {
        return false;
      }
      if (date < this.booking.From) {
        this.booking.Till = this.booking.From;
        this.booking.From = date;
      } else {
        this.booking.Till = date;
      }
      ;

      if (!this.checkDisabledDates(this.booking.Till)) {
        this.showAlert = true;
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
        this.booking.Till = null;
        return false;
      }

      this.booking.From.setHours(0,0,0,0);
      this.booking.Till.setHours(0,0,0,0);

      this.selectionFinished = true;
      this.$emit("input", this.booking);
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < new Date()
        let d = date.toISOString().split('T')[0];
        classes.disabled = this.occupationDays.includes(d);
      }
      return classes
    },
    niceDate(date) {
      return date.toLocaleDateString("nl-NL", {
        weekday: 'short',
        timeZone: 'UTC'
      }) + ", " + date.getDate() + " " + date.toLocaleString("nl-NL", {
        month: 'short',
        timeZone: 'UTC'
      }) + " " + date.getFullYear();
    },
    next() {
      this.$emit('next');
    },
    checkDisabledDates(till) {
      for (let entry of this.occupationDays) {
        if (!this.dateCheck(entry, till))
          return false;
      }
      return true;
    },
    dateCheck(check, till) {
      let fDate, lDate, cDate;
      fDate = Date.parse(this.booking.From);
      lDate = Date.parse(till);
      cDate = Date.parse(check);
      if ((cDate <= lDate && cDate >= fDate)) return false
      return true;

    }
  },
  mounted() {
    if (this.booking.From) {
      this.dateRange.startDate = this.booking.From;
      this.dateRange.endDate = this.booking.Till;
    }
  },
  components: {DateRangePicker},
}
</script>

<style scoped>
  >>> .vue-daterange-picker { width: 100%; }
  >>> .calendars-container { width: 100%; }
  >>> .reportrange-text {display: none;}
  >>> td.off {
    background: #fff !important;
    color: #fff !important;
  }
  >>> .daterangepicker .calendar-table td, .daterangepicker .calendar-table th {
    width: auto; height: 50px; line-height: 50px; user-select: none;
  }
  >>> .daterangepicker .drp-calendar { width: auto; max-width: 100%; }
  >>> .daterangepicker td.in-range {
    background: #e9f7f0;
  }
  >>> .daterangepicker td.active, .daterangepicker td.active:hover {
    background: #60b68c;
  }

  >>> .modal .close {
    background: none;
    border: none;
    font-size: 1.5rem;
  }
  >>> .daterangepicker td.disabled {
    background: #e03d28;
    color: #fff;

  }
</style>