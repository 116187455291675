export class Room {
	ID: number = 0;
	Name: string = "";

	public constructor(data?:any) {
		if (!data) return;
		this.ID = data.id;
		this.Name = data.name;
	}

}