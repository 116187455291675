import {Axios} from "@/utils/axios";
import {Discount} from "@/models/discount";


export class DiscountServiceClass {

	public url: string = "https://booking-api.webcode.be/api/public/discount"
	async validate(code: string) {
		try {
			let response = await Axios.get(this.url + "/validate?code=" + code);
			if (response.status == 200) {
				return new Discount(response.data.data);
			}else{
				return false;
			}
		} catch (e) {
			return false;
		}
	}


}

export const DiscountService = new DiscountServiceClass();