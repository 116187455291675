import {Axios} from "@/utils/axios";
import {Booking} from "@/models/booking";


export class PaymentServiceClass {

	public url: string = "https://booking-api.webcode.be/api/public/payment"
	async init(booking: Booking, amount: Number) {
		try {
			let response = await Axios.post(this.url + "/init",{booking,amount});
			if (response.status == 200 && response.data.url) {
				window.location.href = response.data.url;
				return true;
			}else{
				return false;
			}
		} catch (e) {
			return false;
		}
	}

}

export const PaymentService = new PaymentServiceClass();