<template>
  <b-card class="mt-4">
    <b-row>
      <b>{{ info }}</b>
    </b-row>
    <b-row class="mt-2">
      <b-col sm="6" >
        <b-form-input :class="{error:error && val.FirstName.length==0}" placeholder="Voornaam" v-model="val.FirstName"></b-form-input>
      </b-col>
      <b-col sm="6">
        <b-form-input :class="{error:error && val.LastName.length==0}" placeholder="Achternaam" v-model="val.LastName"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="6">
        <b-form-input placeholder="Geboortedatum" :class="{error:error && val.DateOfBirth.length==0}" v-model="val.DateOfBirth"></b-form-input>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
  import {AdditionalGuest} from "@/models/additional-guest";

  export default {
    data() {
      return {
        val: this.value
      }
    },
    watch: {
      val() {
        this.$emit("input", this.val);
      }
    },
    props: {
      info: String,
      value: [AdditionalGuest],
      error: Boolean
    }
  }
</script>