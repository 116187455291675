<template>
  <div class="mb-5">
    <b-row no-gutters>
      <b-col sm="12">
        <h2>Jouw gegevens</h2>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col class="mt-2" sm="12">
        <main-guest :error="showError" :value="value.MainGuest"/>
      </b-col>
      <b-col v-if="value.AdditionalGuests.length || value.AdditionalChildren.length || value.AdditionalBabies.length"
             sm="12">
        <h2 class="mt-4">Gegevens van medereizigers</h2>
        <additional-guest v-for="(guest,index) in value.AdditionalGuests" :key="'guest'+index"
                          :info="'Extra gast ' + (index+1)"
                          :value="guest"/>
        <additional-guest v-for="(guest,index) in value.AdditionalChildren" :key="'child'+index"
                          :info="'Extra kind ' + (index+1)"
                          :value="guest"/>
        <additional-guest v-for="(guest,index) in value.AdditionalBabies" :key="'baby'+index"
                          :info="'Extra babie ' + (index+1)"
                          :value="guest"/>
      </b-col>
    </b-row>
    <b-row class="mt-3 date-result" no-gutters>
      <b-col class="d-flex" sm="12">
        <b-button @click="prev()">Vorige stap</b-button>
        <div :style="{flexGrow:1}"/>
        <!--<b-button @click="next()">Volgende stap</b-button>-->
      </b-col>
    </b-row>
  </div>
</template>
<script>

import MainGuest from "@/components/guests/MainGuest.vue"
import AdditionalGuest from "@/components/guests/AdditionalGuest.vue"
import {Booking} from "@/models/booking";

export default {
  data() {
    return {
      showError: false
    }
  },
  props: {
    value: [Booking],
    doValidation: Boolean
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    next() {
      if (this.validate())
        this.$emit('next');
    },
    validate() {

      this.showError = false;
      let keys = ['FirstName', 'LastName', 'Street', 'Number', 'Postal', 'City', 'Email', 'Phone', 'DateOfBirth'];

      for (let key in keys) {
        if (this.value.MainGuest[keys[key]] === "") {
          this.showError = true;
          return false;
        }
      }

      keys = ['FirstName', 'LastName', 'DateOfBirth'];

      /*let a = [...this.value.AdditionalGuests, ...this.value.AdditionalChildren, ...this.value.AdditionalBabies];

      for (const [index, item] of Object.entries(a)) {
        for (let key in keys) {
          if (item[keys[key]] === "") {
            this.showError = true;
            return false;
          }
        }
      };
      */

      this.$emit("next");

      return true;
    }
  },
  components: {
    MainGuest,
    AdditionalGuest
  }
}
</script>