<template>
  <b-col class="top mb-5"  md="8" sm="12">
    <h2>Bedankt {{value.MainGuest.FirstName}} voor je boeking</h2>
    <b-card class="mt-3">
      <b-card-body>
        <h1>Bedankt!</h1>
        <p class="mt-2">We kijken er naar uit om je te mogen ontvangen</p>
        <p>Neem alvast een kijkje op onze website voor meer informatie over onze camping.</p>
        <b-link href="https://www.gtkeiheuvel.be" >Naar gtkeiheuvel.be</b-link>
      </b-card-body>
    </b-card>
  </b-col>
</template>
<script>

import {Booking} from "@/models/booking";

export default {
  props: {
    value: [Booking]
  }
}
</script>