<template>
  <div class="mb-5">
    <b-row no-gutters>
      <b-col sm="12">
        <h2>Heb jij een voorkeur van accomodatie?</h2>
      </b-col>
      <b-col class="mt-2 d-flex flex-column" md="4" sm="12">
        <b>Maak hieronder je keuze</b>
        <b-select v-model="selected" :options="options" class="mt-2"></b-select>
      </b-col>
      <b-col class="mt-2" md="8" sm="12">
        <div id="plan" :style="plan"></div>
      </b-col>
    </b-row>
    <b-row class="mt-3 date-result" no-gutters>
      <b-col class="d-flex" sm="12">
        <b-button @click="prev()">Vorige stap</b-button>
        <div :style="{flexGrow:1}"/>
        <b-button @click="next()">Volgende stap</b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {Booking} from "@/models/booking";
import {Room} from "@/models/room";
import {AccomodationService} from "@/services/accomodation-service";
import {BookingService} from "@/services/booking-service";
import {Accomodation} from "@/models/accomodation";

export default {
  data() {
    return {
      booking: this.value,
      rooms: [Room],
      selected: 0
    }
  },
  watch: {
    booking() {
      this.$emit("input", this.booking);
    },
    selected() {
      this.booking.IdRoom = this.selected;
      this.booking.RoomName = this.rooms.filter(r => r.ID === this.selected)[0].Name;
    }

  },
  computed: {
    options() {
      return this.rooms.map((u) => {
            return {value: u.ID, text: u.Name}
          }
      )
    },
    plan(){
      return "background: url(https://gtkeiheuvel.be/public/templates/frontend/default/assets/accomodations/plans/"+ this.accomodation.Plan +") center; " +
          "background-size: contain;" +
          "background-repeat: no-repeat;"
    }
  },
  props: {
    value: [Booking],
    accomodation: [Accomodation]
  },
  methods: {
    prev() {
      this.$emit('prev');
    },
    next() {
      this.$emit('next');
    }
  },
  components: {},
  async mounted() {
    let id = this.$route.query.accomodation;
    //this.rooms = await AccomodationService.rooms(id);
    this.rooms = await BookingService.occupationByAccomodation(id, this.value.From, this.value.Till)
    this.selected = this.value.IdRoom ? this.value.IdRoom : this.rooms[0].ID;

  }
}
</script>

<style>
#plan {
  background: #ededed; min-height: 569px; min-width: 100%;
}
</style>