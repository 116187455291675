<template>
  <div v-if="accomodation.ID">
    <h2>Overzicht</h2>
    <b-card class="mt-3">
      <b-row>
        <b-col sm="12"><b>{{ accomodation.Name }}</b></b-col>
        <b-col v-if="value.RoomName" class=" room-name" sm="12">{{ value.RoomName }}</b-col>
        <b-col class="mt-2" sm="6"><b>Aankomst</b></b-col>
        <b-col class=" mt-2" sm="6"><b>Vertrek</b></b-col>
        <b-col sm="6">{{ niceDate(value.From) }}</b-col>
        <b-col sm="6">{{ niceDate(value.Till) }}</b-col>
        <b-col v-if="value.Till" class="mt-2 mb-2" sm="12"><b>{{ totalGuests }} {{ totalGuests > 1 ? 'personen' : 'persoon' }} /
          {{ value.totalDays() }}
          {{ value.totalDays() > 1 ? 'nachten' : 'nacht' }}</b></b-col>
      </b-row>
      <div v-if="value.Till">
        <b-row class="b-row mt-2">
          <b-col class=" mt-2" sm="12"><b>Reservatiekost:</b></b-col>
          <b-col class=" mt-1" sm="8">Huurprijs</b-col>
          <b-col class=" mt-1 text-end" sm="4">&euro; {{ rent.toFixed(2) }}</b-col>
          <b-col v-if="rentAdditionGuests > 0" class=" mt-1" sm="8">Extra Gasten</b-col>
          <b-col v-if="rentAdditionGuests > 0" class=" mt-1 text-end" sm="4">&euro; {{
              rentAdditionGuests.toFixed(2)
            }}
          </b-col>
        </b-row>
        <div v-if="discountAmount > 0">
          <b-row class="b-row mt-2">
            <b-col class=" mt-2" sm="12"><b>Extra korting voor lang verblijf</b></b-col>
            <b-col class=" mt-1" sm="8">Reeds afgehouden korting</b-col>
            <b-col class=" mt-1 text-end" sm="4">&euro; {{ discountAmount.toFixed(2) }}</b-col>
          </b-row>
        </div>
        <b-row v-if="value.CancellationInsurance || calculatedOptions.length>0" class="b-row mt-2">
          <b-col class=" mt-2" sm="12"><b>Extra opties</b></b-col>
          <b-col v-if="value.CancellationInsurance" class=" mt-1" sm="8">Annulatieverzekering:</b-col>
          <b-col v-if="value.CancellationInsurance" class=" mt-1 text-end" sm="4">&euro;
            {{ cancellationInsuranceCost.toFixed(2) }}
          </b-col>
        </b-row>
        <b-row v-for="option in calculatedOptions" :key="option.ID">
          <b-col class=" mt-1" sm="8">{{ option.Name }}:</b-col>
          <b-col class=" mt-1 text-end" sm="4">&euro;
            {{ (option.Price * option.Qty * (option.Multiply ? value.totalDays() : 1)).toFixed(2) }}
          </b-col>
        </b-row>

        <b-row class="b-row mt-2">
          <b-col class=" mt-2" sm="12"><b>Extra</b></b-col>
        </b-row>
        <b-row v-for="cost in accomodation.Costs" :key="cost.ID">
          <b-col class=" mt-1" sm="8">{{ cost.Name }}</b-col>
          <b-col class=" mt-1 text-end" sm="4">&euro; {{ (cost.Price).toFixed(2) }}
          </b-col>
        </b-row>

        <b-row v-if="this.appliedDiscount" class=" mt-2" md="6" sm="12">
          <b-col>
            <b :style="{color:'green'}" sm="8">{{ appliedDiscount.Name }}</b>
          </b-col>
          <b-col class="text-end" sm="4"><strike>&euro; {{ totalBeforeDiscount.toFixed(2) }}</strike></b-col>
        </b-row>
        <b-row class="total mt-2">
          <b-col class=" mt-2" sm="8"><b>TOTAAL</b></b-col>
          <b-col class=" mt-2 text-end" sm="4">&euro; {{ totalToCharge.toFixed(2) }}</b-col>
        </b-row>
        <b-row v-if="showDiscount && !appliedDiscount" class="total mt-2">
          <b-col class=" mt-2" md="6" sm="12">
            <b-input v-model="DiscountCode" placeholder="Kortingscode"></b-input>
          </b-col>
          <b-col class=" mt-2" md="6" sm="12">
            <b-button @click="validateDiscount()">Toepassen</b-button>
          </b-col>
        </b-row>
        <b-row v-if="step===5 && !showDiscount">
          <b-col class="mt-2 d-flex flex-column" sm="12">
            <b-link @click="showDiscount=true">Wil je gebruikmaken van een kortingscode?</b-link>
          </b-col>
        </b-row>
        <b-row v-if="step===5 || step ===6">
          <b-col class="mt-3 d-flex flex-row" sm="12">
            <b-checkbox v-model="accept" class="me-2"/>
            <div>Ik ga akkoord met <a href="https://gtkeiheuvel.be/reservatievoorwaarden" target="_blank"> de reserveringsvoorwaarden</a>.</div>
          </b-col>
          <b-col class="mt-1 d-flex flex-column" sm="12">
            <b-button class="mt-3 btn-success" @click="next()">Boeking betalen en afronden</b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal ref="modal-1" :header-bg-variant="'danger'" :header-text-variant="'light'" hide-footer
             title="Kortingscode">
      <p class="my-3">Code foutief</p>
    </b-modal>
    <b-modal ref="modal-2" :header-bg-variant="'danger'" :header-text-variant="'light'" hide-footer title="Opgelet">
      <p class="my-3">Gelieve de voorwaarden te accepteren.</p>
    </b-modal>
    <b-modal ref="modal-3" :header-bg-variant="'danger'" :header-text-variant="'light'" hide-footer title="Opgelet">
      <p class="my-3">Er is iets misgelopen met het doorverwijzen naar de betaalpagina</p>
    </b-modal>
  </div>
</template>

<script>
import {Booking} from "@/models/booking";
import {Accomodation} from "@/models/accomodation";
import {DiscountService} from "@/services/discount-service";
import {Discount} from "@/models/discount";
import {PaymentService} from "@/services/payment-service";

export default {
  data() {
    return {
      rentCost: 0,
      additionGuestsCost: 0,
      showDiscount: false,
      DiscountCode: "",
      inValidCode: false,
      appliedDiscount: null | Discount,
      accept: false,
      extraDiscount: 0
    }
  },
  watch: {
    async step() {
      if (this.step == 6) {
        let r = await PaymentService.init(this.value,this.totalToCharge)

        if (!r) {
          this.$refs['modal-3'].show();
          this.$emit('prev');
        }
      }
    }
  },
  computed: {
    rentFull(){
      let rent = 0;
      for (var d = new Date(this.value.From); d < new Date(this.value.Till); d.setDate(d.getDate() + 1)) {
        rent += this.getDayPrice(d);
      }
      return rent;
    },
    rentAdditionGuestsFull(){
      let rent = 0;
      if (this.accomodation.FreeGuestsIncluded == 0) {
        rent = ((this.accomodation.ExtraGuestCost * this.value.totalDays()) * (this.value.Guests));
        rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * (this.value.Children));
      } else {
        if (this.value.Guests > this.accomodation.FreeGuestsIncluded) {
          console.log("1")
          rent += ((this.accomodation.ExtraGuestCost * this.value.totalDays()) * (this.value.Guests - this.accomodation.FreeGuestsIncluded));
        }
        if (this.accomodation.FreeGuestsIncluded > 0 && this.value.Guests >= this.accomodation.FreeGuestsIncluded && this.value.Children > 0) {
          console.log("2")
          rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * this.value.Children);
        }
        if (this.accomodation.FreeGuestsIncluded > 0 && this.value.Guests === 1 && this.value.Children > 1) {
          console.log("3")
          rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * (this.value.Children - 1));
        }
      }
      return rent;
    },
    discountAmount(){
      let discount = 0;
      discount += this.rentFull - this.rent;
      discount += this.rentAdditionGuestsFull - this.rentAdditionGuests;
      return discount
    },
    rent() {
      let rent = 0;
      for (var d = new Date(this.value.From); d < new Date(this.value.Till); d.setDate(d.getDate() + 1)) {
        rent += this.getDayPrice(d);
      }

      if(this.value.totalDays()>=15){
        rent = rent * 0.85;
        return rent;
      }

      if(this.value.totalDays()>=7){
        rent = rent * 0.95;
        return rent;
      }

      return rent
    },
    rentAdditionGuests() {
      let rent = 0

      //2 guests included

      if (this.accomodation.FreeGuestsIncluded == 0) {
        rent = ((this.accomodation.ExtraGuestCost * this.value.totalDays()) * (this.value.Guests));
        rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * (this.value.Children));
      } else {
        if (this.value.Guests > this.accomodation.FreeGuestsIncluded) {
          console.log("1")
         rent += ((this.accomodation.ExtraGuestCost * this.value.totalDays()) * (this.value.Guests - this.accomodation.FreeGuestsIncluded));
        }
        if (this.accomodation.FreeGuestsIncluded > 0 && this.value.Guests >= this.accomodation.FreeGuestsIncluded && this.value.Children > 0) {
          console.log("2")
          rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * this.value.Children);
        }
        if (this.accomodation.FreeGuestsIncluded > 0 && this.value.Guests === 1 && this.value.Children > 1) {
          console.log("3")
          rent += ((this.accomodation.ExtraChildCost * this.value.totalDays()) * (this.value.Children - 1));
        }
      }

      if(this.value.totalDays()>=15){
        rent = rent * 0.85;
        return rent;
      }

      if(this.value.totalDays()>=7){
        rent = rent * 0.95;
        return rent;
      }

      return rent;
      //return rent === 0 ? 0 : (rent - this.rent)

    },
    cancellationInsuranceCost() {

      if (!this.value.CancellationInsurance)
        return 0;

      return (this.total / 100 * 8.5)
    },
    total() {
      let t = 0;
      t += (parseFloat(this.rent + this.rentAdditionGuests) + this.value.DepositCost);
      let _ = this;

      this.value.Options.forEach(function (item) {
        if (item.Qty > 0) {
          let p = (item.Qty * item.Price);
          console.log(item.Multiply);
          if (item.Multiply) {
            t += p * _.value.totalDays();
          } else {
            t += p;
          }

        }
      });

      return parseFloat(t);
    },
    accomodationExtraCosts() {

      let t = 0;
      let _ = this
      this.accomodation.Costs.forEach(function (item) {
        let p = item.Price;
        if (item.Multiply) {
          t += p * _.value.totalDays();
        } else {
          t += p;
        }
      });

      return t;

    },
    totalBeforeDiscount() {
      let t = this.total

      t += this.accomodationExtraCosts;
      t += this.cancellationInsuranceCost;

      return parseFloat(t);
    },
    totalToCharge() {
      let a = parseFloat(this.total);

      if (this.appliedDiscount) {
        if (this.appliedDiscount.Type === 1) {
          a = a - this.appliedDiscount.Discount
        }
        if (this.appliedDiscount.Type === 2) {
          a = a * ((100 - this.appliedDiscount.Discount) / 100);
        }
      }

      a += this.accomodationExtraCosts;
      a += this.cancellationInsuranceCost

      return a;
    },
    calculatedOptions() {
      return this.value.Options.filter((o) => o.Qty > 0);
    },
    totalGuests() {
      return this.value.Guests + this.value.Children + this.value.Babies;
    }
  },
  props: {
    value: [Booking],
    accomodation: [Accomodation],
    step: Number,
    tiny: Boolean
  },
  methods: {
    niceDate(date) {

      if (!date) {
        return "-"
      }

      console.log(date);

      return date.toLocaleDateString("nl-NL", {
        weekday: 'short',
        timeZone: 'Europe/Brussels'
      }) + ", " + date.getDate() + " " + date.toLocaleString("nl-NL", {
        month: 'short',
        timeZone: 'Europe/Brussels'
      }) + " " + date.getFullYear();
    },
    getDayPrice(date) {

      if (!this.value)
        return 0;

      let p = 0;

      this.accomodation.Pricing.forEach(function (item) {
        let from = new Date(item.From);
        let till = new Date(item.Till);
        if ((date <= till.setHours(0,0,0,0) && date >= from.setHours(0,0,0,0)) || date == from.setHours(0,0,0,0)) {
          p = item.Price;
        }
      });

      return parseFloat(p);
    },
    async validateDiscount() {
      let c = await DiscountService.validate(this.DiscountCode);
      this.$refs['modal-1'].hide()
      if (!c) {
        this.$refs['modal-1'].show()
      } else {
        this.appliedDiscount = c;
      }
    },
    next() {
      this.$refs['modal-2'].hide()
      if (!this.accept) {
        this.$refs['modal-2'].show()
        return
      }
      this.$emit('next');
    },
  },
  mounted() {

  }

}
</script>

<style>
.modal-header button { background: none; border: none; font-size: 19px; }
hr { height: 20px; border-bottom: 1px solid #000; margin-top: 10px; }
.options { border-top: 1px solid #ccc; }
.b-row { border-top: 1px solid #ccc; }
.total { border-top: 1px solid #ccc; font-size: 18px; }
.room-name { opacity: 0.8; }

</style>