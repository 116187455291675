<template>
  <div class="d-flex flex-row justify-content-around" :style="{userSelect:'none'}">
    <b-icon-dash-circle class="h2 m-2" @click="remove()"/>
    <b-form-input :style={maxWidth:mw} v-model="val" :min="min" type="number" :disabled="val===max || disabled" @blur="onBlur()"></b-form-input>
    <b-icon-plus-circle class="h2 m-2" @click="add()"/>
  </div>
</template>
<script>

export default {
  data() {
    return {
      val: 0,
      editVal: 0
    }
  },
  props: {
    max: Number,
    min: Number,
    value: Number,
    maxWidth: Number,
    disabled: Boolean
  },
  watch: {
    value() {
      this.val = this.value;
    }
  },
  computed:{
    mw(){ return this.maxWidth ? this.maxWidth + "px" : ""  }
  },
  methods: {
    onBlur(){
      if (this.val >= this.max) {
        this.val = this.max;
        this.$emit('update:val', this.max)
      }else if(this.val <= this.min){
        this.val = this.min;
        this.$emit('update:val', this.min)
      }
    },
    add() {
      if (this.value < this.max) {
        this.$emit('update:val', this.value + 1)
      }
    },
    remove() {
      if (this.value > this.min) {
        this.$emit('update:val', this.value - 1)
      }
    }

  },
  mounted() {
    this.val = this.value
  }
};


</script>