export class MainGuest {
	FirstName: string = "";
	LastName: string = "";
	Postal: string = "";
	Street: string = "";
	Number: string = "";
	City: string = "";
	Country: string = "";
	Email: string = "";
	Phone: string = "";
	DateOfBirth: string = "";
	Info: string = "";

	public constructor(data?: any) {
		if (!data) return;
		this.FirstName = data.FirstName;
		this.LastName = data.LastName;
		this.Postal = data.Postal;
		this.Street = data.Street;
		this.Number = data.Number;
		this.City = data.City;
		this.Country = data.Country;
		this.Email = data.Email;
		this.Phone = data.Phone;
		this.DateOfBirth = data.DateOfBirth;
		this.Info = data.Info;
	}
}