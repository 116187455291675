<template>
  <b-card>
    <b-row>
      <b-col sm="6">
        <b-form-input :class="{error:error && val.FirstName.length==0}" v-model="val.FirstName" placeholder="Voornaam"></b-form-input>
      </b-col>
      <b-col sm="6">
        <b-form-input :class="{error:error && val.LastName.length==0}" v-model="val.LastName"  placeholder="Achternaam"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="6">
        <b-form-input :class="{error:error && val.Street.length==0}" v-model="val.Street" placeholder="Straatnaam"></b-form-input>
      </b-col>
      <b-col sm="6">
        <b-form-input :class="{error:error && val.Number.length==0}" v-model="val.Number" placeholder="Huisnummer"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="6">
        <b-form-input :class="{error:error && val.Postal.length==0}" v-model="val.Postal" placeholder="Postcode"></b-form-input>
      </b-col>
      <b-col sm="6">
        <b-form-input :class="{error:error && val.City.length==0}" v-model="val.City" placeholder="Plaats / gemeente"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="6">
        <b-form-input :class="{error:error && val.Email.length==0}" v-model="val.Email" placeholder="E-mailadres"></b-form-input>
      </b-col>
      <b-col sm="6">
        <b-form-input :class="{error:error && val.Phone.length==0}" v-model="val.Phone" placeholder="GSM-nummer"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="6">
        <b-form-input :class="{error:error && val.DateOfBirth.length==0}" v-model="val.DateOfBirth" placeholder="Geboortedatum"></b-form-input>
      </b-col>
      <b-col sm="6">
        <b-form-input :class="{error:error && val.Country.length==0}" v-model="val.Country" placeholder="Land"></b-form-input>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col sm="12">
        <b-form-textarea v-model="val.Info" placeholder="Opmerkingen"></b-form-textarea>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
  import {MainGuest} from "@/models/main-guest";

  export default {
    data() {
      return {
        val: this.value
      }
    },
    props: {
      info: String,
      value: [MainGuest],
      error: Boolean
    },
    watch: {
      val() {
        this.$emit("input", this.val);
      }
    },
  }
</script>
