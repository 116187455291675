import {Axios} from "@/utils/axios";
import {Booking} from "@/models/booking";
import {Room} from "@/models/room";


export class BookingServiceClass {

	public url: string = "https://booking-api.webcode.be/api/public/booking"

	async recover(t: string) {
		try {
			let response = await Axios.get(this.url + "/recover?t=" + t);
			if (response.status == 200) {
				let b = response.data.data.booking;
				if (response.data.data.payed == 1) {
					b.Payed = 1
				}
				return new Booking(b);
			} else {
				return new Booking();
			}
		} catch (e) {
			return false;
		}
	}

	async occupation(a: number, s: Date) {
		try {

			let date = s.getUTCFullYear() + "-" + (s.getMonth()) + "-" + s.getUTCDate();

			let response = await Axios.get(this.url + "/occupation?a=" + a + "&s=" + date);
			if (response.status == 200 && response.data) {
				return response.data.data.occupation;
			} else {

			}
		} catch (e) {
			console.log(e);
			return false;
		}
	}

	async occupationByAccomodation(a: string, from: Date, till: Date) {
		try {

			let f = from.getUTCFullYear() + "-" + (from.getMonth()+1) + "-" + from.getUTCDate();
			let t = till.getUTCFullYear() + "-" + (till.getMonth()+1) + "-" + till.getUTCDate();

			let response = await Axios.get(this.url + "/occupation-accomodation?a=" + a + "&from=" + f+ "&till=" + t);
			if (response.status == 200 && response.data) {
				return response.data.data.occupation.map((r:Room) => { return new Room(r) });
			} else {

			}
		} catch (e) {
			console.log(e);
			return false;
		}
	}

}

export const BookingService = new BookingServiceClass();